/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";

import { SetPageTitle, PageTitle } from "./page_title";

const BlogLayout = ({ pageContext, children }) => {
  const { title } = pageContext.frontmatter;

  return (
    <>
      <SetPageTitle title={title} />
      <div>THIS IS A BLOG POST</div>
      <PageTitle title={title} />
      {children}
    </>
  );
};

export default BlogLayout;
